<template>
  <fieldset :class="{ 'formQuestion': true }">
    <div v-if="question" :class="{ 'formContent': true}">
      <legend class="quizSubheading" v-html="question.question" />
      <div class="checkbox-container" :class="{ 'colors': question.colors }">
        <div
          v-for="(inputFields, index) in question.inputFields"
          :id="questionsRefId || question?.questionsRefId"
          :key="index"
          :class="{ 'checkboxItem': true, 'checkboxItemWrap': !question.colors  }"
        >
          <div class="option-content">
            <label  :for="(questionsRefId || question?.questionsRefId) + '--' + index" class="option-content">
                <CloudinaryImage
                v-if="inputFields.imagePublicID"
                :public-id="inputFields.imagePublicID"
                class="option-image"
              />
              <div v-if="inputFields.color" :class="inputFields.color"></div>
              <div class="option-label">{{ inputFields.text }}</div>
            </label>
            <div class="checkbox-wrapper">
              <input
                :value="inputFields.value || inputFields.text "
                type="checkbox"
                :name="(questionsRefId || question?.questionsRefId) + '--' + index"
                :id="(questionsRefId || question?.questionsRefId) + '--' + index"
                v-model="checkboxValues"
                @change="checkValue"
                class="option-checkbox"
              />
              <span class="custom-checkbox"></span>
            </div>
          </div>
        </div>
      </div>
      <ValidationWarning :valid="valid" />
    </div>
  </fieldset>
</template>

<script>
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  props: {
    showSpecialElementsKey: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    question: {
      type: Object,
      default: () => {}
    },
    questionsRefId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkboxValues: [],
      previousValues: [],
      valid: true,
      initialized: false
    }
  },
  mounted () {
    this.checkboxValues = this.modelValue || []
  },
  components: {
    CloudinaryImage
  },
  methods: {
    checkValue () {
      if (!this.previousValues.includes('No thanks') && this.checkboxValues.includes('No thanks')) {
        this.checkboxValues = ['No thanks']
      }
      let hasOther = false
      this.checkboxValues.forEach((value) => {
        if (value !== 'No thanks') hasOther = true
      })
      if (this.previousValues.includes('No thanks') && hasOther) {
        this.checkboxValues.splice(this.checkboxValues.indexOf('No thanks'), 1)
      }
      this.previousValues = this.checkboxValues
    },
    validate () {
      this.valid = !(this.required && !this.checkboxValues.length && this.initialized)
      return this.valid
    }
  },
  watch: {
    checkboxValues () {
      this.$emit('update:modelValue', this.checkboxValues)
      this.validate()
      this.initialized = true
    }
  }
}
</script>

<style scoped>

.formQuestion {
  display: flex;
  flex-direction: column;
}

.formContent {
  padding: 10px;
}

.quizSubheading {
  font-family: "Sohne";
  color: #0b1326;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  gap: 20px;
  padding: 20px;
}

.checkboxItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 300px;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: 0;
}

.checkboxItemWrap{
  width: calc(33% - 20px);
}

.checkboxItemWrap:nth-child(n+4) {
  width: calc(40% - 20px);
}

@media (max-width: 768px) {
  .checkboxItemWrap,
  .checkboxItemWrap:nth-child(n+4) {
    width: calc(50% - 10px);
    max-width: none;
  }
}

.option-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.option-image {
  max-width: 100%;
  width: 200px;
  height: auto;
}

.option-label {
  font-size: 1rem;
  color: #333;
  text-align: center;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.checkbox-wrapper input {
  width: 20px;
  height: 20px;
  border: 1px solid #00402180;
  background: transparent;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px !important;
}

.checkbox-wrapper input:checked {
  background-color: #004021;
}

.checkbox-wrapper input:checked::after {
  content: "\e904";
  font-family: 'icomoon';
  font-size: 9px;
  background-color: transparent;
  color: #fff;
}

.custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
}

.colors{
 gap: 60px;
}
.red{
  background-color: rgb(128, 27, 27);
}
.blue{
  background-color: rgb(44, 44, 156);
}
.green{
  background-color: rgb(35, 87, 35);
}
.red,.blue,.green{
  display: flex;
  max-width: 100%;
  max-height: 100%;
  width: 50px;
  height: 50px;
}
</style>
