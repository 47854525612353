<template>
  <div
    class="boards__item"
    :class="{
      'boards__item--pending': board.isPending && !showAppointment,
      'boards__item--in-progress': board.inProgress
    }" >

    <template v-if="this.isLoading"><Loader :visible="true" :indeterminate="true"/></template>

    <template v-else>
    <h2 class="boards__item__title" v-if="board.responses && board.responses.room">
      {{ board.responses.room || 'My Room' }}
    </h2>

    <!-- On site measurement appointment details -->
    <div v-if="showAppointment" class="boards__item__on-site-measurement">
      <div class="boards__item__on-site-measurement__central">
        <div class="boards__item__on-site-measurement__card">
          <table>
            <tr>
              <td>Representative</td>
              <td>{{ board.onSiteServiceDetails.representative }}</td>
            </tr>
            <tr>
              <td>Date/Time</td>
              <td>{{ formattedDateTime }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ board.onSiteServiceDetails.status.toUpperCase() }}</td>
            </tr>
          </table>
        </div>
      </div>
      <h3 class="boards__item__status">On-Site Measurement Requested.<br>We'll see you soon!</h3>
    </div>

    <!-- Progress wheel displays when survey is in progress -->
    <template v-if="board.inProgress">
      <div class="boards__item__progress">
        <ProgressPie :progress="board.currentProgress / 100 || 0" />
      </div>
      <h3 class="boards__item__status"><i class="fas fa-list"></i> Survey in Progress</h3>
    </template>

    <template v-if="!board.inProgress && !board.selection && needsAppointment">
      <div class="boards__item__copy">
        <!-- We'll probably replace this image eventually -->
        <img
          src="/img/step2-img-grey.jpg"
          alt="Boards in progress"
          class="boards__item__image"
        />
        <p>
          <!-- We'll reach out to you shortly to schedule the best time for us to visit you. -->
          Thanks for scheduling your on-site service and we'll see you soon.
        </p>
      </div>
    </template>

    <!-- If the user completed their measurements but doesn't have selections, show this note -->
    <template
      v-if="
        !board.inProgress &&
        !board.selection &&
        !board.isPending &&
        pendingFurnitureSelection
      "
    >
      <div class="boards__item__copy 222222">
        <img
          src="/img/loading-gif.gif"
          alt="Boards in progress"
          class="boards__item__image"
        />
        <p>
          We&rsquo;re currently selecting the perfect options for your new {{ board.responses.type_of_furniture }}. We will notify you by email when they are ready.
        </p>
      </div>
    </template>
    <template
      v-if="
        !board.inProgress &&
        !board.selection &&
        board.isPending && !needsAppointment
      "
    >
      <div class="boards__item__copy 111111">
        <img
          src="/img/step2-img-grey.jpg"
          alt="Boards in progress"
          class="boards__item__image"
        />
        <p>
          We&rsquo;re currently selecting the perfect options for your new {{ board.responses.type_of_furniture }}. We will notify you by email when they are ready.
        </p>
      </div>
    </template>
    <!-- Once furniture is selected, we'll display it here -->
    <template v-if="!board.inProgress && board.selection">
      <div class="boards__item__media">
        <div class="boards__item__media__item" v-for="(furniture, index) in allBoardsFurniture" :key="index">
          <img
            v-if="furniture.cloudinaryImages && furniture.cloudinaryImages[0]"
            :class="{
              'product__images__item__image': fee,
              'product__images__item__image_blur': !fee
            }"
            :src="furniture.cloudinaryImages[0].url"
          >
        </div>
      </div>
      <h3 class="boards__item__status">
        <template v-if="!board.selection.chosen || !board.selection.stripeOrderComplete">
          Your boards are ready!
        </template>
        <!-- If the user has chosen from the selection and paid, we'll show this. -->
        <template v-else-if="board.selection.stripeOrderComplete">
          Your {{ board.projectName || 'Furniture' }} is on the way!
        </template>
        <template v-else>
          Project Complete
        </template>
      </h3>
    </template>

    <!-- Variable action buttons -->
    <div class="boards__item__cta">
      <a
        v-if="board.inProgress"
        class="button primary"
        @click="$emit('continue')"
      >
        Continue Survey <i data-v-05613f85="" class="fas fa-arrow-right"></i>
      </a>
      <a v-else-if="needsAppointment" class="button tertiary">
        On-Site Service Pending
      </a>
      <router-link v-else-if="showAppointment" class="button" to="/contact">
        Contact Us To Reschedule
      </router-link>
      <a v-else-if="board.isPending" class="button tertiary">
        Selections Coming Soon
      </a>

      <!-- View the target selection -->
      <a
        v-else-if="board.selection && !board.selection.stripeOrderComplete"
        class="button primary"
        @click="$emit('view')"
      >
        Make Selections
      </a>

      <!-- View the specific order associated with this selection -->
      <router-link
        v-else-if="board.selection && board.selection.stripeOrderComplete"
        class="button"
        :to=" `/orders?id=${board.selection.stripeOrderId}`"
      >
        View Order
      </router-link>

      <template v-if="includeUtilities">
        <div v-if="!board.selection" class="button--debug">
          <br>
          <a @click="addSelectionToProject({ user: currentUser.uid, survey: board.id })">
            Generate selection for this project
          </a>
        </div>
        <div v-if="!board.onSiteServiceDetails" class="button--debug">
          <a @click="addOnSiteServiceDetails({ user: currentUser.uid, survey: board.id })">Add On Site Service Details</a>
        </div>
        <div
          v-if="board.onSiteServiceDetails && board.onSiteServiceDetails.status !== 'complete'"
          class="button--debug"
        >
          <a @click="markOnSiteServiceComplete({ user: currentUser.uid, survey: board.id })">
            Mark On Site Service Complete
          </a>
        </div>
      </template>
    </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { defineComponent } from 'vue'
import ProgressPie from '@/components/ProgressPie'
import Flickity from 'flickity'
import Loader from '@/components/Loader.vue'

export default defineComponent({
  name: 'Board',
  props: ['boards', 'fee'],
  components: {
    ProgressPie,
    Loader
  },
  data () {
    return {
      board: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState(['includeUtilities']),
    // Should the appointment details be shown for this board?
    showAppointment () {
      return this.board.onSiteServiceDetails?.status === 'pending' && !this.board.selection
    },
    // Does an appointment still need to be schedule for this board?
    needsAppointment () {
      return this.board?.responses?.specs_options === 'on-site-service' && !this.board?.onSiteServiceDetails?.status
    },
    pendingFurnitureSelection () {
      return this.board?.onSiteServiceDetails?.status === 'complete' || this?.board?.responses?.specs_options === 'diy'
    },
    /**
     * Consolidates both boards' furniture into a single array for easier display in carousel.
     */
    allBoardsFurniture () {
      return [
        ...this.board.selection.boards[0].furniture,
        ...this.board.selection.boards[1].furniture
      ]
    },
    formattedDateTime () {
      if (!this.board?.onSiteServiceDetails?.date) return ''
      const isoDate = this.board.onSiteServiceDetails.date
      const date = new Date(isoDate).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
      return date
    }
  },
  methods: {
    ...mapMutations(['setPageLoading']),
    ...mapActions(['addSelectionToProject', 'addOnSiteServiceDetails', 'markOnSiteServiceComplete', 'getDetailedSelection']),

    slideshow: function () {
      var boardSlideshows = document.getElementsByClassName('boards__item__media')
      var len = boardSlideshows !== null ? boardSlideshows.length : 0
      for (var i = 0; i < len; i++) {
        var boardSlideshow = boardSlideshows[i]
        // eslint-disable-next-line no-new
        new Flickity(boardSlideshow, {
          imagesLoaded: true,
          fade: true,
          prevNextButtons: false,
          pageDots: false,
          autoPlay: 2000,
          pauseAutoPlayOnHover: false,
          draggable: false,
          accessibility: false
        })
      }
    },
    async forceRerender () {

    }
  },
  async mounted () {
    this.board = this.boards

    this.board.selection = await this.getDetailedSelection(this.board.id)
    this.setPageLoading(false)
    this.isLoading = false
    await this.forceRerender()

    setTimeout(() => {
      this.slideshow()
    }, 100)
  }
})
</script>

<style lang="scss" scoped>
  .boards__item__image {
    margin: var(--gutter) auto;
    display: block;
    height: 100px;
  }
  .boards__item__cta {
    .button--debug {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .product__images__item__image_blur{
    filter: blur(3px);
  }
  .boards__item__osm-details-toggle {
    display: inline-block;
    margin-left: .5em;
  }
</style>
