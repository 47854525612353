<template>
  <div
    class="carousel"
    @mousedown="onDragStart"
    @mousemove="onDragMove"
    @mouseup="onDragEnd"
    @mouseleave="onDragEnd"
    @touchstart="onDragStart"
    @touchmove="onDragMove"
    @touchend="onDragEnd"
  >
    <div
      class="carousel-track"
      :style="{ transform: `translateX(-${translateX}%)`, transition: isTransitioning ? 'transform 0.5s ease-in-out' : 'none' }"
      @transitionend="onTransitionEnd"
    >
      <div
        v-for="(image, index) in extendedImages"
        :key="index"
        class="carousel-slide"
      >
        <CloudinaryImage :public-id="image" class="carousel-image" />
      </div>
    </div>
  </div>
</template>

<script>
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  name: 'HomePagePhotosCarusel',
  components: {
    CloudinaryImage
  },
  props: {
    manualMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      images: [
        'ToTree/webapp/static/home-page-hero-1',
        'ToTree/webapp/static/home-page-hero-2',
        'ToTree/webapp/static/home-page-hero-3',
        'ToTree/webapp/static/home-page-hero-4',
        'ToTree/webapp/static/home-page-hero-5',
        'ToTree/webapp/static/home-page-hero-6'
      ],
      currentIndex: 0,
      isTransitioning: true,
      isDragging: false,
      startX: 0,
      currentX: 0,
      translateX: 0
    }
  },
  computed: {
    extendedImages () {
      return [...this.images, ...this.images]
    }
  },
  mounted () {
    if (!this.manualMode) {
      this.startCarousel()
    }
  },
  beforeUnmount () {
    this.stopCarousel()
  },
  methods: {
    startCarousel () {
      this.carouselInterval = setInterval(() => {
        if (this.currentIndex === this.images.length) {
          this.isTransitioning = false
          this.currentIndex = 0
        } else {
          this.isTransitioning = true
          this.currentIndex++
        }
        this.translateX = this.currentIndex * 100
      }, 3000)
    },
    stopCarousel () {
      clearInterval(this.carouselInterval)
    },
    onTransitionEnd () {
      if (!this.isTransitioning) {
        this.isTransitioning = true
      }
    },
    onDragStart (event) {
      if (!this.manualMode) return
      this.stopCarousel()
      this.isDragging = true
      this.startX = event.touches ? event.touches[0].clientX : event.clientX
    },
    onDragMove (event) {
      if (!this.isDragging) return
      const currentX = event.touches ? event.touches[0].clientX : event.clientX
      const deltaX = currentX - this.startX
      this.translateX = this.currentIndex * 100 - (deltaX / window.innerWidth) * 100
    },
    onDragEnd (event) {
      if (!this.isDragging) return
      this.isDragging = false

      const endX = event.changedTouches ? event.changedTouches[0].clientX : event.clientX
      const deltaX = endX - this.startX

      if (deltaX > 50) {
        this.currentIndex = Math.max(this.currentIndex - 1, 0)
      } else if (deltaX < -50) {
        this.currentIndex = Math.min(this.currentIndex + 1, this.images.length - 1)
      }

      this.translateX = this.currentIndex * 100

      if (!this.manualMode) {
        this.startCarousel()
      }
    }
  }
}
</script>

<style scoped>
.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  user-select: none;
  cursor: grab;
}

.carousel:active {
  cursor: grabbing;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  flex: 0 0 100%;
  height: auto;
}

.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
