<template>
  <fieldset class="form__question question-div">
    <div :class="{ 'form__content': true, 'softStyle': shouldShowImageDiv }">
      <legend class="quiz__subheading" :class="{ 'meta': metaTitle }" v-html="question.question" />
      <div :class="{'radiosWrapper': true, 'customLayout': shouldShowImageDiv  }">
      <div v-for="(inputFields, index) in fields" :key="index" :class="{'form__item form__item--radio': true, 'customLayout--child': shouldShowImageDiv, 'customLayout--child-1': shouldShowImageDiv && index === 0}">
        <input
          :value="inputFields.value || inputFields.text"
          type="radio"
          :name="(questionsRefId || question?.questionsRefId) + '--' + index"
          :id="(questionsRefId || question?.questionsRefId) + '--' + index"
          v-model="radioValue"
          @input="updateValue"
          @focusout="handleFocusout"
          class="customRadio"
          @change="handleRadioChange"
        >
        <label :for="(questionsRefId || question?.questionsRefId) + '--' + index">{{ inputFields.text }}</label>
        <label class="sofaimage" :for="(questionsRefId || question?.questionsRefId) + '--' + index" v-if="shouldShowImageDiv">
          <img :src="require(`@/assets/images/${inputFields.image}`)" class="image" alt="Image of a furniture">
        </label>
        <div class="instaorder"  v-if="index === 0 && menuType === 'timeline'">Insta-order <i class="icon-circle-info"></i> <span>Don't want to sit on milk crates for a month? With <strong>Insta-Order</strong>, all of your furniture options will be in-stock and ready to ship.</span></div>
      </div>
      </div>
      <ValidationWarning :valid="valid" />
    </div>
  </fieldset>
</template>

<script>
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'

export default {
  components: { ValidationWarning },
  props: {
    menuType: String,
    typeName: String,
    modelValue: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    questionsRefId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    metaTitle: {
      type: Boolean,
      default: false
    },
    autoProceed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    shouldShowImageDiv () {
      // Check if the typeName is 'type_of_sofa'
      return this.typeName === 'type_of_sofa'
    },
    fields () {
      return this.shouldShowImageDiv ? [...this.question.inputFields].reverse() : this.question.inputFields
    }
  },
  data () {
    return {
      radioValue: null,
      valid: true
    }
  },
  mounted () {
    this.radioValue = this.modelValue || ''
  },
  methods: {
    validate () {
      this.valid = !(this.required && !this.modelValue)

      return this.valid
    },
    handleRadioChange (event) {
      this.updateValue(event)

      // Check if autoProceed is true
      if (this.autoProceed) {
        // Emit a custom event to notify the parent component
        this.$emit('radioClicked', this.radioValue)
      }
    },
    /**
     * This is tied to the input v-model and is called as the user
     * updates the input on the page. It live updates inputValue
     * and passes it to the grandparent in Quiz.vue
     *
     * @param {*} e This is the on update event that is returned when the user makes an update.
     */
    updateValue (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
  watch: {
    modelValue: {
      handler (val) {
        this.validate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meta {
  @include meta-text;
  margin-bottom: var(--gutter) !important;
  font-size: 0.75rem !important;
}
.radiosWrapper {
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 var(--gutter);
}
.customLayout {
  display: flex;
  align-items: center;
  flex-flow: column-reverse;
  @media (min-width: 768px) {
    flex-flow: row-reverse wrap-reverse;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    grid-column-start: 3;
    grid-column-end: 11;
  }

  &--child {
    flex: 0 0 30%;
    @media (max-width: 768px) {
      flex: 1;
      width: 80%;
    }
  }
}

.softStyle {
grid-column: span 12;
}

.image {
  max-height: 128px !important;
  width: auto !important;
}

</style>
