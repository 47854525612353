<template>
  <div
    class="form__style-radio-3 form__style-radio--horizontal style-radio-wrapper"
    :class="{ 'form__style-radio--horizontal--two': options.length == 2, 'form__style-radio--horizontal--narrow': narrow, 'dresser': options.find(opt=> opt.value==='horizontal'), 'limitHeight': options.find(opt=> opt.value==='accent')  }"
  >
    <div
      v-for="(option, index) in options"
      :key="`option-${index}`"
      class="form__style-radio--horizontal__item"
      :class="{ 'form__style-radio--horizontal__item--two': options.length == 2, 'place-checked': isChecked(option) }"
    >
      <div class="form__item form__item--radio">
        <label class="label" :class="{ imageOnTop: option.value !== 'horizontal' && !options.find(opt=> opt.value==='accent')}" :for="(questionsRefId || `option-${index}`) + '--' + index" >
          <template v-if="option.green_img">
            <img :src="require(`@/assets/images/${option.green_img}`)" class="green_img">
          </template>
          <template v-if="option.image">
            <img :src="require(`@/assets/images/${option.image}`)" class="normal_img">
          </template>
          <template  v-if="option.imagePublicID">
            <div class="cloudinaryImage" :class="{'dresserV' : option.imagePublicID.includes('vertical'), 'limitAccent' :option.imagePublicID.includes('living_room/color/accent') }"><CloudinaryImage :public-id="option.imagePublicID" /></div>
          </template>
          <template v-if="option.label">
            <span class="optionLabel" >{{option.label}}</span>
          </template>
          <div class="radio-container" :class="{ 'has-label': !!option.label }">
          <input
            :value="option.value"
            type="radio"
            :name="(questionsRefId || 'option') + '--' + index"
            :id="(questionsRefId || 'option') + '--' + index"
            @input="updateValue"
            v-model="radioValue"
            @change="handleRadioChange"
            class="customRadio"
          >
        </div>
        </label>

      </div>
    </div>
    <ValidationWarning :valid="valid" />
  </div>
</template>

<script>
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  name: 'StyleRadio',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    questionsRefId: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    autoProceed: {
      type: Boolean,
      default: true
    },
    narrow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      radioValue: ''
    }
  },
  components: {
    CloudinaryImage
  },
  computed: {
    isChecked () {
      return (option) => this.radioValue === option.value
    }
  },
  mounted () {
    this.radioValue = this.modelValue || ''
  },
  methods: {
    updateValue (event) {
      this.$emit('update:modelValue', event.target.value)
    },
    handleRadioChange (event) {
      this.updateValue(event)

      // Check if autoProceed is true
      if (this.autoProceed) {
        // Emit a custom event to notify the parent component
        this.$emit('radioClicked', this.radioValue)
      }
    },
    validate () {
      this.valid = !(this.required && this.modelValue.length < 1)
      return this.valid
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.validate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form__item {
  &--radio {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    height: 100%;
  }
}

.form__style-radio {
  &--horizontal__item {
      // padding: 2rem 2rem 1rem 2rem;
      max-width: 250px;
  }

}

.label{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 32px 32px 16px 32px !important;

}

.imageOnTop > :first-child {
  margin-bottom: auto;

}

.style-radio-wrapper{
  gap: 0 !important;
 img{
  max-height:200px;
  max-width: 100%;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
 }
}
.dresser{
  img{
    max-height: 142px !important;
  }
}

.optionLabel{
  white-space: normal;
  word-break: normal;
}

.limitHeight{
  img{
    max-height:112px !important;
  }
}

.cloudinaryImage{
  display: flex;
  max-width: 100%;
}

@media (max-width:800px){
  .dresserV{
    margin: 0 40px;
  }

  .limitAccent{
    margin: 0 20px;
  }
}

@media (max-width:780px){

  .limitAccent{
    margin: 0 25px;
  }
}

@media (max-width:630px){
  .dresserV{
    margin: 0 20px;
  }

  .limitAccent{
    margin: 0 25px;
  }
}

@media (max-width:510px){

  .limitAccent{
    margin: 0 15px;
  }
}

@media (max-width:420px){
  .dresserV{
    margin: 0 10px;
  }
  .limitAccent{
    margin: 0 10px;
  }
}
</style>
