<template>
  <div class="offeringsContainer">
    <h2 class="offeringsHeader">The Furniture</h2>
    <div class="offeringsDescription">
      We offer foundational furniture for living rooms, bedrooms and dining rooms from a variety of trusted brands.
    </div>
    <div class="itemsContainer">
      <div v-for="(slide, index) in slides"
               :key="index"
               class="items">
            <div class="offeringsDesktop">
              <h3 class="offeringsItemsHeader">{{ slide.title }}</h3>
              <div class="offeringsItem" v-for="(item, itemIndex) in slide.items" :key="itemIndex">
                <span class="itemTitle">{{ item.title }}</span>
                <CloudinaryImage :public-id="item.publicId" />
                <span class="itemPrice">Starting at: ${{ item.price }}</span>
              </div>
            </div>
          </div>
    </div>
    <div class="carousel-container">
      <div class="carousel"
           @mousedown="startDrag"
           @touchstart="startDrag"
           @mouseup="endDrag"
           @touchend="endDrag"
           @mousemove="onDrag"
           @touchmove="onDrag"
           @mouseleave="endDrag">
        <div class="carousel-content"
             :style="carouselStyle">
          <div class="carousel-slide">
            <h3 class="offeringsItemsHeader">{{ slides[slides.length - 1].title }}</h3>
            <div class="offerings">
              <div class="offeringsItem" v-for="(item, itemIndex) in slides[slides.length - 1].items" :key="`last-${itemIndex}`">
                <span class="itemTitle">{{ item.title }}</span>
                <CloudinaryImage :public-id="item.publicId" />
                <span class="itemPrice">Starting at: ${{ item.price }}</span>
              </div>
            </div>
          </div>

          <div v-for="(slide, index) in slides"
               :key="index"
               class="carousel-slide">
            <h3 class="offeringsItemsHeader">{{ slide.title }}</h3>
            <div class="offerings">
              <div class="offeringsItem" v-for="(item, itemIndex) in slide.items" :key="itemIndex">
                <span class="itemTitle">{{ item.title }}</span>
                <CloudinaryImage :public-id="item.publicId" />
                <span class="itemPrice">Starting at: ${{ item.price }}</span>
              </div>
            </div>
          </div>

          <div class="carousel-slide">
            <h3 class="offeringsItemsHeader">{{ slides[0].title }}</h3>
            <div class="offerings">
              <div class="offeringsItem" v-for="(item, itemIndex) in slides[0].items" :key="`first-${itemIndex}`">
                <span class="itemTitle">{{ item.title }}</span>
                <CloudinaryImage :public-id="item.publicId" />
                <span class="itemPrice">Starting at: ${{ item.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-dots">
      <button v-for="(slide, index) in slides"
              :key="index"
              :class="['dot', { active: currentIndex === index }]"
              @click="goToSlide(index)">
      </button>
    </div>
    <div class="brandsContainer">
      <img class="brandImage" src="../assets/images/brands/bludot.png" alt="bluedot">
      <img class="brandImage" src="../assets/images/brands/sabai.png" alt="sabai">
      <img class="brandImage" src="../assets/images/brands/surya.png" alt="surya">
      <img class="brandImage" src="../assets/images/brands/eq3.png" alt="eq3">
      <img class="brandImage" src="../assets/images/brands/benchmade.png" alt="benchmade">
      <img class="brandImage" src="../assets/images/brands/burrow.png" alt="burrow">
      <img class="brandImage" src="../assets/images/brands/westelm.png" alt="westelm">
      <img class="brandImage" src="../assets/images/brands/thuma.png" alt="thuma">
      <img class="brandImage" src="../assets/images/brands/roomandboard.png" alt="roomandboard">
      <img class="brandImage" src="../assets/images/brands/article.png" alt="article">
    </div>
  </div>
</template>

<script>
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  name: 'Offerings',
  components: {
    CloudinaryImage
  },
  data () {
    return {
      currentIndex: 0,
      isDragging: false,
      startPos: 0,
      currentTranslate: 0,
      prevTranslate: 0,
      slideWidth: 0,
      isTransitioning: false,
      slides: [
        {
          title: 'Living room',
          items: [
            { title: 'Coffee tables', publicId: 'ToTree/webapp/survey/offering_living_room_coffee_table', price: 699 },
            { title: 'Couches', publicId: 'ToTree/webapp/survey/offering_living_room_sofa', price: 1199 },
            { title: 'Chairs', publicId: 'ToTree/webapp/survey/offering_living_room_chair', price: 699 },
            { title: 'Sideboards media consoles', publicId: 'ToTree/webapp/survey/offering_living_room_sideboard_media_console', price: 699 },
            { title: 'Rugs', publicId: 'ToTree/webapp/survey/offering_living_room_rug', price: 699 },
            { title: 'End tables', publicId: 'ToTree/webapp/survey/offering_living_room_end_table', price: 299 }
          ]
        },
        {
          title: 'Bedroom',
          items: [
            { title: 'Nightstands', publicId: 'ToTree/webapp/survey/offering_bedroom_nightstand', price: 299 },
            { title: 'Beds', publicId: 'ToTree/webapp/survey/offering_bedroom_bed', price: 799 },
            { title: 'Benches', publicId: 'ToTree/webapp/survey/offering_bedroom_bench', price: 699 },
            { title: 'Dressers', publicId: 'ToTree/webapp/survey/offering_bedroom_dresser', price: 699 },
            { title: 'Rugs', publicId: 'ToTree/webapp/survey/offering_bedroom_rug_nuctqq', price: 699 }
          ]
        },
        {
          title: 'Dining room',
          items: [
            { title: 'Tables', publicId: 'ToTree/webapp/survey/offering_dining_room_table', price: 899 },
            { title: 'Tables', publicId: 'ToTree/webapp/survey/offering_dining_room_table_2', price: 499 },
            { title: 'Chairs', publicId: 'ToTree/webapp/survey/offering_dining_room_chair', price: 699 },
            { title: 'Stools', publicId: 'ToTree/webapp/survey/offering_dining_room_stool', price: 699 },
            { title: 'Rugs', publicId: 'ToTree/webapp/survey/offering_dining_room_rug_xvzya5', price: 699 }
          ]
        }
      ]
    }
  },
  computed: {
    carouselStyle () {
      return {
        transform: `translateX(${this.currentTranslate}px)`,
        transition: this.isDragging ? 'none' : 'transform 0.3s ease-out'
      }
    }
  },
  mounted () {
    this.slideWidth = this.$el.querySelector('.carousel').offsetWidth
    this.currentTranslate = -this.slideWidth
    window.addEventListener('resize', this.handleResize)
    const content = this.$el.querySelector('.carousel-content')
    content.addEventListener('transitionend', this.handleTransitionEnd)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
    const content = this.$el.querySelector('.carousel-content')
    content.removeEventListener('transitionend', this.handleTransitionEnd)
  },
  methods: {
    handleResize () {
      this.slideWidth = this.$el.querySelector('.carousel').offsetWidth
      this.goToSlide(this.currentIndex, false)
    },
    startDrag (event) {
      if (this.isTransitioning) return
      this.isDragging = true
      this.startPos = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
      this.prevTranslate = this.currentTranslate
    },
    onDrag (event) {
      if (!this.isDragging || this.isTransitioning) return
      const currentPosition = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
      const diff = (currentPosition - this.startPos) * 1.5
      this.currentTranslate = this.prevTranslate + diff
    },
    endDrag () {
      if (!this.isDragging || this.isTransitioning) return
      this.isDragging = false
      const movedBy = this.currentTranslate - this.prevTranslate

      if (Math.abs(movedBy) > this.slideWidth * 0.1) {
        if (movedBy < 0) {
          this.nextSlide()
        } else {
          this.prevSlide()
        }
      } else {
        this.goToSlide(this.currentIndex)
      }
    },
    nextSlide () {
      const totalSlides = this.slides.length
      this.currentIndex++

      if (this.currentIndex >= totalSlides) {
        this.currentIndex = 0
        this.isTransitioning = true
      }

      this.goToSlide(this.currentIndex)
    },
    prevSlide () {
      this.currentIndex--

      if (this.currentIndex < 0) {
        this.currentIndex = this.slides.length - 1
        this.isTransitioning = true
      }

      this.goToSlide(this.currentIndex)
    },
    goToSlide (index, animate = true) {
      this.currentIndex = index
      this.currentTranslate = -(index + 1) * this.slideWidth

      if (!animate) {
        this.isDragging = true
        this.$nextTick(() => {
          this.isDragging = false
        })
      }
    },
    handleTransitionEnd () {
      if (this.isTransitioning) {
        this.isDragging = true
        if (this.currentIndex === 0) {
          this.currentTranslate = -this.slideWidth
        } else if (this.currentIndex === this.slides.length - 1) {
          this.currentTranslate = -this.slides.length * this.slideWidth
        }
        this.$nextTick(() => {
          this.isDragging = false
          this.isTransitioning = false
        })
      }
    }
  }
}
</script>

<style scoped>
.offeringsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px;
}

.offeringsInfoBold {
  font-weight: 500;
}

.offeringsHeader {
  font-size: 40px;
  font-weight: 500;
  margin: 30px auto 16px auto;
  color: #00321A;
}

.offeringsDescription {
  margin-bottom: 60px !important;
  text-align: center;
  margin: 0 auto 24px auto;

}

.itemsContainer{
  display: flex;
  gap: 24px;
  @media (max-width: 768px) {
   display: none;
  }
}

.carousel-container {
  display: none;
  position: relative;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 60px auto;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;

  @media (max-width: 768px) {
   display: flex;
  }
}

.carousel {
  width: 100%;
  overflow: hidden;
  touch-action: pan-y pinch-zoom;
}

.carousel-content {
  display: flex;
  width: 100%;
  flex-shrink: 0;
}

.carousel-slide {
  min-width: 100%;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offeringsDesktop{
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: 1px solid #0C372033;
  border-radius: 8px;
}

.offerings {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  padding: 0 2rem;
}

.offeringsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: calc(50% - 1rem);

  @media (max-width: 1400px) {
  width: auto;
  }
}

.offeringsItem img {
  width: auto;
  max-width: 200px;
  height: auto;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.carousel-dots {
  display: none;
  justify-content: center;
  margin-top: 20px;
  gap: 0.5rem;

  @media (max-width: 768px) {
   display: flex;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background-color: #ccc;
  cursor: pointer;
  padding: 0;
}

.dot.active {
  background-color: #333;
}

.offeringsItemsHeader {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}

.description {
  text-align: center;
  margin-bottom: 40px;
}

.brandsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto 20px;
  padding: 0;
  max-width:2000px;
  margin-top: 100px;
}

.brandImage {
  width: calc(16.666% - 17px);
  object-fit: contain;
  flex: 0 0 auto;

@media (max-width: 1200px) {
    width: calc(20% - 16px);
}

@media (max-width: 992px) {
    width: calc(25% - 15px);
}

@media (max-width: 768px) {
    width: calc(33.33% - 14px);
}

@media (max-width: 576px) {
    width: calc(50% - 10px);
}

}

.itemTitle {
  margin-bottom: 12px;
  font-weight: 500;
}

.itemPrice {
  margin-top: 12px;
  font-size: 14px;
}

.offeringsDeliveryMessage {
  font-weight: 600;
  text-align: center;
}
</style>
