<template>
  <div class="summaryContainer">
    <div class="summaryHeaderWrapper">
      <span>Congratulations!</span>
      <span>Your furniture selections are in the works!</span>
    </div>
    <div class="timelineWrapper">
      <div class="info">As a reminder, here's what you told us about your living room needs:</div>
      <div class="timeline">{{ responses.your_timeline === 0 ? "I'm in no real rush" : "I needed my furniture yesterday" }} </div>
      <div class="timelineNotes"> {{ responses.timeline_notes }} </div>
    </div>
    <div class="styleChoice">
      <div class="info">Your choice style</div>
      <div class="selectedStyles"> {{ responses.favorite_styles.join(" ,") }} </div>
    </div>
    <span class="info">Your furniture preferences</span>

    <!-- Living room furnitures -->
    <div v-if="responses.type_of_furniture === 'living-room'">
      <!-- Sofa -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('sofa')">
        <span class="header">Sofa</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.sofa.budget }}</span>
          <span class="budgetRange">{{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.sofa.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.sofa_fabric_options.inputFields.find(el => el.text === responses.furnitures.sofa.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.sofa_type_options.find(el => el.value === responses.furnitures.sofa.type)?.imagePublicID" />
            {{ quizData.sofa_type_options.find(el => el.value === responses.furnitures.sofa.type)?.label }}
          </div>
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.sofa_style_options.find(el => el.value === responses.furnitures.sofa.style)?.imagePublicID" />
            {{ quizData.sofa_style_options.find(el => el.value === responses.furnitures.sofa.style)?.label }}
          </div>
        </div>
      </div>
      <!-- Chair -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('chair')">
        <span class="header">Chair</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.chair.budget }}</span>
          <span class="budgetRange">{{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.chair.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.chair_fabric_options.inputFields.find(el => el.text === responses.furnitures.chair.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chair_type_options.find(el => el.value === responses.furnitures.chair.type)?.imagePublicID" />
            {{ quizData.chair_type_options.find(el => el.value === responses.furnitures.chair.type)?.label }}
          </div>
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chair_style_options.find(el => el.value === responses.furnitures.chair.style)?.imagePublicID" />
            {{ quizData.chair_style_options.find(el => el.value === responses.furnitures.chair.style)?.label }}
          </div>
        </div>
      </div>
      <!-- Sideboard -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('sideboard')">
        <span class="header">Sideboard</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.sideboard.budget }}</span>
          <span class="budgetRange">{{ quizData.sideboard_budget_options.inputFields.find(budget => budget.value === responses.furnitures.sideboard.budget).info }}</span>
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.sideboard_type_options.find(el => el.value === responses.furnitures.sideboard.type)?.imagePublicID" />
            {{ quizData.sideboard_type_options.find(el => el.value === responses.furnitures.sideboard.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Coffee table -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('coffee_table')">
        <span class="header">Coffee table</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.coffee_table.budget }}</span>
          <span class="budgetRange">{{ quizData.coffee_budget_options.inputFields.find(budget => budget.value === responses.furnitures.coffee_table.budget).info }}</span>
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.coffee_type_options.find(el => el.value === responses.furnitures.coffee_table.type)?.imagePublicID" />
            {{ quizData.coffee_type_options.find(el => el.value === responses.furnitures.coffee_table.type)?.label }}
          </div>
          <div v-for="(surface, surfaceIndex) in responses.furnitures.coffee_table.surfaces" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.coffee_surface_options.inputFields.find(el => el.value === surface)?.imagePublicID" />
            {{ quizData.coffee_surface_options.inputFields.find(el => el.value === surface)?.text }}
          </div>
          <div class="furniture" v-for="(shape, shapeIndex) in responses.furnitures.coffee_table.shapes" :key="shapeIndex">
            <CloudinaryImage :public-id="quizData.coffee_shape_options.inputFields.find(el => el.value === shape)?.imagePublicID" />
            {{ quizData.coffee_shape_options.inputFields.find(el => el.value === shape)?.text }}
          </div>
        </div>
      </div>
      <!-- End table -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('end_table')">
        <span class="header">End table</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.end_table.budget }}</span>
          <span class="budgetRange">{{ quizData.end_table_budget_options.inputFields.find(budget => budget.value === responses.furnitures.end_table.budget).info }}</span>
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.end_table_type_options.find(el => el.value === responses.furnitures.end_table.type)?.imagePublicID" />
            {{ quizData.end_table_type_options.find(el => el.value === responses.furnitures.end_table.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Rug -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('rug')">
        <span class="header">Rug</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.rug.budget }}</span>
          <span class="budgetRange">{{ quizData[`rug_budget_${responses.furnitures.rug.size}_options`]?.inputFields.find(
                budget => budget.value === responses.furnitures.rug.budget
                )?.info }}</span>
        </div>
        <div class="selectedOptions">
          <div v-for="(style, surfaceIndex) in responses.furnitures.rug.style" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.rug_style_options.inputFields.find(el => el.value === style)?.imagePublicID" />
          </div>
        </div>
      </div>
      <!-- Color -->
      <div class="section">
        <span class="header">Color</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.living_room_colors_options.find(el => el.value === responses.colorIntensity)?.imagePublicID" />
            {{ quizData.living_room_colors_options.find(el => el.value === responses.colorIntensity)?.label }}
          </div>
        </div>
      </div>
    </div>
    <!-- Bedroom furnitures -->
    <div v-else-if="responses.type_of_furniture === 'bedroom'">
      <!-- Bed -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('bed')">
        <span class="header">Bed</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.bed.budget }}</span>
          <span class="budgetRange">{{ quizData.bed_budget_options.inputFields.find(budget => budget.value === responses.furnitures.bed.budget).info }}</span>
        </div>
        <div class="fabricInfo" v-if="responses.furnitures.bed?.materials">
          {{ quizData.bed_fabric_options.inputFields.find(el => el.text === responses.furnitures.bed.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.bed_type_options.find(el => el.value === responses.furnitures.bed.type)?.imagePublicID" />
            {{ quizData.bed_type_options.find(el => el.value === responses.furnitures.bed.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Dresser -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('dresser')">
        <span class="header">Dresser</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.dresser.budget }}</span>
          <span class="budgetRange">{{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.dresser.budget).info }}</span>
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.dresser_type_options.find(el => el.value === responses.furnitures.dresser.type)?.imagePublicID" />
            {{ quizData.dresser_type_options.find(el => el.value === responses.furnitures.dresser.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Nightstand -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('nightstand')">
        <span class="header">Nightstand</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.nightstand.budget }}</span>
          <span class="budgetRange">{{ quizData.nightstand_budget_options.inputFields.find(budget => budget.value === responses.furnitures.nightstand.budget).info }}</span>
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.nightstand_type_options.find(el => el.value === responses.furnitures.nightstand.type)?.imagePublicID" />
            {{ quizData.nightstand_type_options.find(el => el.value === responses.furnitures.nightstand.type)?.label }}
          </div>
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.nightstand_surface_options.find(el => el.value === responses.furnitures.nightstand.surface)?.imagePublicID" />
            {{ quizData.nightstand_surface_options.find(el => el.value === responses.furnitures.nightstand.surface)?.label }}
          </div>
        </div>
      </div>
      <!-- Chair -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('chair')">
        <span class="header">Chair</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.chair.budget }}</span>
          <span class="budgetRange">{{ quizData.budget_options.inputFields.find(budget => budget.value === responses.furnitures.chair.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.chair_fabric_options.inputFields.find(el => el.text === responses.furnitures.chair.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chair_bedroom_type_options.find(el => el.value === responses.furnitures.chair.type)?.imagePublicID" />
            {{ quizData.chair_bedroom_type_options.find(el => el.value === responses.furnitures.chair.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Bench -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('bench')">
        <span class="header">Bench</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.bench.budget }}</span>
          <span class="budgetRange">{{ quizData.bench_budget_options.inputFields.find(budget => budget.value === responses.furnitures.bench.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.bench_fabric_options.inputFields.find(el => el.text === responses.furnitures.bench.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.bench_type_options.find(el => el.value === responses.furnitures.bench.type)?.imagePublicID" />
            {{ quizData.bench_type_options.find(el => el.value === responses.furnitures.bench.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Rug -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('rug')">
        <span class="header">Rug</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.rug.budget }}</span>
          <span class="budgetRange">{{ quizData[`rug_budget_${responses.furnitures.rug.size}_options`]?.inputFields.find(
                budget => budget.value === responses.furnitures.rug.budget
                )?.info }}</span>
        </div>
        <div class="selectedOptions">
          <div v-for="(style, surfaceIndex) in responses.furnitures.rug.style" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.rug_style_options.inputFields.find(el => el.value === style)?.imagePublicID" />
          </div>
        </div>
      </div>
      <!-- Color -->
      <div class="section">
        <span class="header">Color</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.bedroom_colors_options.find(el => el.value === responses.colorIntensity)?.imagePublicID" />
            {{ quizData.bedroom_colors_options.find(el => el.value === responses.colorIntensity)?.label }}
          </div>
        </div>
      </div>
    </div>
    <!-- Dining Room furnitures -->
    <div v-if="responses.type_of_furniture === 'dining-room'">
      <!-- Table -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('dining_table')">
        <span class="header">Table</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.dining_table.budget }}</span>
          <span class="budgetRange">{{ quizData.dining_table_budget_options.inputFields.find(budget => budget.value === responses.furnitures.dining_table.budget).info }}</span>
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.table_type_options.find(el => el.value === responses.furnitures.dining_table.type)?.imagePublicID" />
            {{ quizData.table_type_options.find(el => el.value === responses.furnitures.dining_table.type)?.label }}
          </div>
          <div v-for="(shape, shapeIndex) in responses.furnitures.dining_table.shape" :key="shapeIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.table_shape_options.inputFields.find(el => el.value === shape)?.imagePublicID" />
            {{ quizData.table_shape_options.inputFields.find(el => el.value === shape)?.text }}
          </div>
        </div>
      </div>
      <!-- Chairs -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('dining_chairs')">
        <span class="header">Chairs</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.dining_chairs.budget }}</span>
          <span class="budgetRange">{{ quizData.dining_chairs_budget_options.inputFields.find(budget => budget.value === responses.furnitures.dining_chairs.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.chairs_fabric_options.inputFields.find(el => el.text === responses.furnitures.dining_chairs.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.chairs_type_options.find(el => el.value === responses.furnitures.dining_chairs.type)?.imagePublicID" />
            {{ quizData.chairs_type_options.find(el => el.value === responses.furnitures.dining_chairs.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Stools -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('stools')">
        <span class="header">Stools</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.stools.budget }}</span>
          <span class="budgetRange">{{ quizData.stools_budget_options.inputFields.find(budget => budget.value === responses.furnitures.stools.budget).info }}</span>
        </div>
        <div class="fabricInfo">
          {{ quizData.stools_fabric_options.inputFields.find(el => el.text === responses.furnitures.stools.materials).text }}
        </div>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.stools_type_options.find(el => el.value === responses.furnitures.stools.type)?.imagePublicID" />
            {{ quizData.stools_type_options.find(el => el.value === responses.furnitures.stools.type)?.label }}
          </div>
        </div>
      </div>
      <!-- Rug -->
      <div class="section" v-if="replaceFurnitures?.length && replaceFurnitures.includes('rug')">
        <span class="header">Rug</span>
        <div class="budgetInfo">
          <span class="budgetLabel">{{ responses.furnitures.rug.budget }}</span>
          <span class="budgetRange">{{ quizData[`rug_budget_${responses.furnitures.rug.size}_options`]?.inputFields.find(
                budget => budget.value === responses.furnitures.rug.budget
                )?.info }}</span>
        </div>
        <div class="selectedOptions">
          <div v-for="(style, surfaceIndex) in responses.furnitures.rug.style" :key="surfaceIndex" class="furniture">
            <CloudinaryImage :public-id="quizData.rug_style_options.inputFields.find(el => el.value === style)?.imagePublicID" />
          </div>
        </div>
      </div>
      <!-- Color -->
      <div class="section">
        <span class="header">Color</span>
        <div class="selectedOptions">
          <div class="furniture">
            <CloudinaryImage :public-id="quizData.dining_room_colors_options.find(el => el.value === responses.colorIntensity)?.imagePublicID" />
            {{ quizData.dining_room_colors_options.find(el => el.value === responses.colorIntensity)?.label }}
          </div>
        </div>
      </div>
    </div>

    <div class="congratulationsBottomText">
      <span>Now let's get started on your next room!</span>
    </div>
    <div class="customActions">
      <router-link to="/projects">
        <button class="btn-Border">
          No thanks, I'm good
        </button>
      </router-link>
      <router-link to="/quiz">
        <button class="customGreenBTN">
          I'm ready, let's do it
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>

import * as quizData from '@/components/quiz/quizDataNew'
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  name: 'SurveySummary',
  created () {
    if (this.$route.query.data) {
      try {
        this.responses = JSON.parse(this.$route.query.data)
      } catch (e) {
        console.error('Error parsing data from URL:', e)
      }
    }
  },
  data () {
    return {
      responses: {},
      quizData,
      replaceFurnitures: []
    }
  },
  watch: {
    responses: {
      handler (newResponses) {
        this.replaceFurnitures = newResponses?.replace_furnitures
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    CloudinaryImage
  }
}
</script>

<style lang="scss" scoped>
.summaryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 2.5rem;
  padding: 2.5rem 1.25rem 1.25rem 1.25rem;
  font-family: "Sohne";
  font-size: 1rem;
  color: #0b1326;
}

.summaryHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'League Spartan';
  color: #004021;
  margin-bottom: 1.875rem;
}

.summaryHeaderWrapper :first-child {
  font-size: 2.1875rem !important;
  font-weight: 700;
}

.summaryHeaderWrapper :nth-child(2) {
  font-size: 1.25rem !important;
  font-weight: 600;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5625rem 0 1.5625rem 0;
  border: none;
  padding: 0;
}

.selectedOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.furniture {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  font-weight: 400;
}

.furniture img {
  max-height: 18.75rem;
}

.budgetInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.budgetLabel {
  font-size: 1.25rem;
  font-weight: 500;
}

.budgetRange {
  font-weight: 400;
}

.timeline {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1.25rem;
}

.header {
  font-size: 2.5rem;
  font-weight: 500;
  padding: 1.25rem 0 1.25rem 0;
}

.info {
  font-family: "Sohne";
  color: #0b1326;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  padding: 0 24px;
  text-align: center;
}

.fabricInfo{
  font-family: "Sohne";
  color: #0b1326;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.congratulationsBottomText {
  font-family: 'League Spartan';
  font-size: 1.875rem;
  font-weight: 600;
  color: #004021;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
}

.customActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  margin-top: 0.9375rem;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
  }
}

.selectedStyles {
  font-size: 1.5rem;
  font-weight: 300;
}

.timelineWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5625rem 0 2.1875rem 0;
  border: none;
  padding: 0;
  max-width: 37.5rem;
}

.styleChoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.9375rem 0 2.1875rem 0;
  border: none;
  padding: 0;
}
</style>
