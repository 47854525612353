<template>
  <fieldset class="form-question" v-if="question?.question">
    <legend class="quizSubheading" v-html="question.question" />
    <div class="radio-group-horizontal">
      <div
        v-for="(inputField, index) in question.inputFields"
        :key="index"
        class="radio-option-horizontal"
      >
        <label :for="(questionsRefId || question?.questionsRefId) + '--' + index" class="radio-label">
          {{ inputField.text }}
          <p class="radio-info">{{ inputField.info }}</p>
        </label>
        <input
          :value="inputField.value"
          type="radio"
          :name="(questionsRefId || question?.questionsRefId) + '--' + index"
          :id="(questionsRefId || question?.questionsRefId) + '--' + index"
          v-model="radioValue"
          @input="updateValue"
          @focusout="handleFocusout"
          class="radio-input"
          @change="handleRadioChange"
        />
      </div>
    </div>
    <ValidationWarning :valid="valid" />
  </fieldset>
</template>

<script>
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'

export default {
  components: { ValidationWarning },
  props: {
    menuType: String,
    typeName: String,
    modelValue: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    questionsRefId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    metaTitle: {
      type: Boolean,
      default: false
    },
    autoProceed: {
      type: Boolean,
      default: true
    },
    type_dependent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldShowImageDiv () {
      return this.typeName === 'type_of_sofa'
    },
    fields () {
      return this.shouldShowImageDiv ? [...this.question.inputFields].reverse() : this.question.inputFields
    }
  },
  data () {
    return {
      radioValue: null,
      valid: true
    }
  },
  mounted () {
    this.radioValue = this.modelValue || ''
  },
  methods: {
    validate () {
      this.valid = !(this.required && !this.modelValue)

      return this.valid
    },
    handleRadioChange (event) {
      this.updateValue(event)

      if (this.autoProceed) {
        this.$emit('radioClicked', this.radioValue)
      }
    },
    updateValue (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
  watch: {
    modelValue: {
      handler (val) {
        this.validate()
      }
    }
  }
}
</script>

<style scoped>
.form-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.quizSubheading {
  font-family: "Sohne";
  color: #0b1326;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;
}
.radio-group-horizontal {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-option-horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.radio-label {
  font-size: 16px;
  font-weight: bold;
}

.radio-info {
  font-size: 14px;
  color: #555;
  margin: 0;
  font-weight: 400;
}

.radio-input {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(0 90 36 / 50%);
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  position: relative;

}

.radio-input:checked {
  background:#004021 !important;
}

.radio-input:checked::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
