<template>
  <fieldset :class="{ 'form__question': true, 'fieldset-color': showSpecialElementsKey }">
    <div v-if="question" :class="{ 'form__content': true, 'color_div': showSpecialElementsKey }">
      <legend class="quiz__subheading" v-html="question.question" />
      <div v-for="(inputFields, index) in question.inputFields" :id="question.questionsRefId" :key="index" :class="{ 'form__item form__item--checkbox custom-checkbox' : true, 'custom_New_checkbox' : !showSpecialElementsKey, 'showImage': showImage}">
        <div v-if="showSpecialElementsKey" class="color_image_div"> </div>
        <div v-if="inputFields?.imagePublicID">
          <CloudinaryImage :public-id="inputFields.imagePublicID" />
          <label :for="(questionsRefId || question?.questionsRefId) + '--' + index">{{ inputFields.text }}</label>
        </div>
        <div >
            <input
              :value="inputFields.value"
              type="checkbox"
              :name="(questionsRefId || question?.questionsRefId) + '--' + index"
              :id="(questionsRefId || question?.questionsRefId) + '--' + index"
              v-model="checkboxValues"
              @change="checkValue"
            >
            <template v-if="!inputFields?.imagePublicID"> <label :for="question.questionsRefId + '--' + index">{{ inputFields.text }}</label></template>
        </div>
      </div>
      <ValidationWarning :valid="valid" />
    </div>
  </fieldset>
</template>

<script>
import CloudinaryImage from '@/components/CloudinaryImage.vue'

export default {
  props: {
    showSpecialElementsKey: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    question: {
      type: Object,
      default: () => {}
    },
    questionsRefId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      deafult: false
    },
    showImage: Boolean
  },
  data () {
    return {
      checkboxValues: [],
      previousValues: [],
      valid: true,
      initialized: false
    }
  },
  mounted () {
    this.checkboxValues = this.modelValue || []
  },
  components: {
    CloudinaryImage
  },
  methods: {
    checkValue () {
      // Choosing "no thanks" clears all other values
      if (!this.previousValues.includes('No thanks') && this.checkboxValues.includes('No thanks')) {
        this.checkboxValues = ['No thanks']
      }
      // If the user chooses something else, we'll clear "no thanks"
      let hasOther = false
      this.checkboxValues.forEach((value) => {
        if (value !== 'No thanks') hasOther = true
      })
      if (this.previousValues.includes('No thanks') && hasOther) {
        this.checkboxValues.splice(this.checkboxValues.indexOf('No thanks'), 1)
      }
      this.previousValues = this.checkboxValues
    },
    validate () {
      this.valid = !(this.required && !this.checkboxValues.length && this.initialized)
      return this.valid
    }
  },
  watch: {
    checkboxValues () {
      this.$emit('update:modelValue', this.checkboxValues)
      this.validate()
      this.initialized = true
    }
  }
}
</script>
<style scoped>
.showImage{
display: flex;
flex-direction: column;
}
</style>
