/**
 * This centralized JS file contains data about all questions that may be asked during the quiz.
 * It helps the already sprawling Quiz.vue from getting too bloated with extra data
 */
/* eslint-disable camelcase */

/*
   ______   _______  _______ _________ _______  _______
  (  ___ \ (  ___  )(  ____ \\__   __/(  ____ \(  ____ \
  | (   ) )| (   ) || (    \/   ) (   | (    \/| (    \/
  | (__/ / | (___) || (_____    | |   | |      | (_____
  |  __ (  |  ___  |(_____  )   | |   | |      (_____  )
  | (  \ \ | (   ) |      ) |   | |   | |            ) |
  | )___) )| )   ( |/\____) |___) (___| (____/\/\____) |
  |/ \___/ |/     \|\_______)\_______/(_______/\_______)
*/
// Step - What kind of place?
export const what_kind_of_place = [
  {
    value: 'House',
    green_img: 'place-house-color.jpg',
    image: 'place-house.jpg',
    label: 'House'
  },
  {
    value: 'Apartment/condo/co-op',
    green_img: 'place-condo-color.jpg',
    image: 'place-condo.jpg',
    label: 'Apartment/condo/co-op'
  },
  {
    value: 'Other',
    green_img: 'place-other-color.jpg',
    image: 'place-other.jpg',
    label: 'Other'
  }
]

// Textarea
export const describe_other = {
  question: 'Please tell us more about the place you are furnishing',
  questionsRefId: 'describeOther-0'
}

// Radio
export const upgrading_or_from_scratch = {
  questionsRefId: 'existing-2',
  question: 'Are you looking to upgrade your existing furniture or are you starting from scratch (an empty space)?',
  inputFields: [
    {
      text: 'Upgrading the old stuff',
      value: 'upgrading'
    },
    {
      text: 'I’ve got a blank canvas',
      value: 'blank-canvas'
    }
  ]
}

// Radio
export const need_help = {
  question: 'Furnishing an empty space can be daunting. Need some help with the layout?',
  questionsRefId: 'needHelp-2',
  inputFields: [
    {
      value: true,
      text: 'Sure, I could use a little layout expertise'
    },
    {
      value: false,
      text: 'No thanks, I’ve got it covered'
    }
  ]
}

// Radio
export const type_of_furniture = {
  questionsRefId: 'whatfor-2',
  question: 'While we can help furnish as many rooms as you need, we break them down one at a time. Where would you like to start?',
  inputFields: [
    {
      value: 'living-room',
      text: 'Living room'
    },
    {
      value: 'bedroom',
      text: 'Bedroom'
    },
    {
      value: 'dining-room',
      text: 'Dining room'
    }
    // KEEP: OFFICE
    // {
    //   value: 'office',
    //   text: 'Office'
    // }
  ]
}

// Textfield
export const project_name = {
  question: 'Now it’s time to give your survey a name. (We\'ll refer to your project with this name)',
  placeholder: 'i.e. Living Room Upgrade'
}

// Checkbox
export const replace_living_room = {
  question: 'Looking for specific items? Select ‘em here.',
  inputFields: [
    {
      value: 'sofa',
      text: 'Sofa'
    },
    {
      value: 'chair',
      text: 'Chair'
    },
    {
      value: 'sideboard',
      text: 'Sideboard / media console'
    },
    {
      value: 'coffee_table',
      text: 'Coffee table'
    },
    {
      value: 'end_table',
      text: 'Side table'
    },
    {
      value: 'rug',
      text: 'Rug'
    }
    // KEEP: OFFICE
    // {
    //   value: 'office-furniture',
    //   text: 'Office furniture'
    // },
  ]
}

// Checkbox
export const replace_bedroom = {
  question: "Looking for specific items? Select 'em here.",
  inputFields: [
    {
      value: 'bed',
      text: 'Bed'
    },
    {
      value: 'nightstand',
      text: 'Nightstands'
    },
    {
      value: 'dresser',
      text: 'Dresser'
    },
    {
      value: 'rug',
      text: 'Rug'
    },
    {
      value: 'chair',
      text: 'Chair'
    },
    {
      value: 'bench',
      text: 'Bench'
    }
  ]
}

// Checkbox
export const replace_dining_room = {
  question: "Looking for specific items? Select 'em here.",
  inputFields: [
    {
      value: 'dining_table',
      text: 'Dining table'
    },
    {
      value: 'dining_chairs',
      text: 'Dining chairs'
    },
    {
      value: 'stools',
      text: 'Stools'
    },
    {
      value: 'rug',
      text: 'Rug'
    }
  ]
}

// Checkbox
export const replace_office = {
  question: 'Sometimes a few specific items are all you need. If that’s the case, just check the appropriate boxes. (And if you’re not sure, take a look at our <a href="/glossary" target="_blank">glossary</a>!)',
  inputFields: [
    {
      value: 'standing_desk',
      text: 'Standing desk'
    },
    {
      value: 'desk',
      text: 'Desk'
    },
    {
      value: 'desk_chair',
      text: 'Desk chair'
    },
    {
      value: 'rug',
      text: 'Rug'
    }
  ]
}

// Radio
export const your_timeline = {
  question: 'What’s your timeline?',
  inputFields: [
    {
      text: 'I needed my furniture yesterday',
      value: 1
    },
    {
      text: 'I\'m in no real rush',
      value: 0
    }
  ]
}

// Textarea
export const timeline_notes = {
  question: 'Do you have any important details for us to know about your furniture needs for this room?'
}

// Radio with Imagery
export const wood_tone_options = [
  {
    value: 'light',
    green_img: 'wood1.jpg',
    image: 'wood1.jpg'
  },
  {
    value: 'dark',
    green_img: 'wood2.jpg',
    image: 'wood2.jpg'
  }
]

/*
   _     _       _              ______
  | |   (_)     (_)             | ___ \
  | |    ___   ___ _ __   __ _  | |_/ /___   ___  _ __ ___
  | |   | \ \ / / | '_ \ / _` | |    // _ \ / _ \| '_ ` _ \
  | |___| |\ V /| | | | | (_| | | |\ \ (_) | (_) | | | | | |
  \_____/_| \_/ |_|_| |_|\__, | \_| \_\___/ \___/|_| |_| |_|
                          __/ |
                          |___/
*/

export const sofa_type_options = [
  {
    value: 'standard',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/standard',
    label: 'Standard',
    questionsRefId: 'sofa-type'
  },
  {
    value: 'chaise_sectional',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/chaise_sectional',
    label: 'Chaise sectional',
    questionsRefId: 'sofa-type'
  },
  {
    value: 'l_shaped_sectional',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/l_shaped_sectional',
    label: 'L-shaped sectional',
    questionsRefId: 'sofa-type'
  },
  {
    value: 'sleeper',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/sleeper',
    label: 'Sleeper',
    questionsRefId: 'sofa-type'
  },
  {
    value: 'modular',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/modular',
    label: 'Modular',
    questionsRefId: 'sofa-type'
  }
]

export const sofa_style_options = [
  {
    value: 'style_1',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/style_1',
    label: '',
    questionsRefId: 'sofa-style'
  },
  {
    value: 'style_2',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/style_2',
    label: '',
    questionsRefId: 'sofa-style'
  },
  {
    value: 'style_3',
    imagePublicID: 'ToTree/webapp/survey/living_room/sofa/style_3',
    label: '',
    questionsRefId: 'sofa-style'
  }
]

export const sofa_fabric_options = {
  question: 'Sofas are available in fabric and leather. Select one.',
  inputFields: [
    {
      text: 'Leather'
    },
    {
      text: 'Fabric'
    }
  ]
}

export const chair_type_options = [
  {
    value: 'standard',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/standard',
    label: 'Stationary / standard'
  },
  {
    value: 'recliner',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/recliner',
    label: 'Recliner'
  },
  {
    value: 'swivel',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/swivel',
    label: 'Swivel'
  }
]

export const chair_bedroom_type_options = [
  {
    value: 'standard',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/standard',
    label: 'Stationary / standard'
  },
  {
    value: 'recliner',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/recliner',
    label: 'Recliner'
  },
  {
    value: 'swivel',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/swivel',
    label: 'Swivel'
  }
]

export const chair_style_options = [
  {
    value: 'style_1',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/style_1',
    label: ''
  },
  {
    value: 'style_2',
    imagePublicID: 'ToTree/webapp/survey/shared/chair/style_2',
    label: ''
  }
]

export const chair_fabric_options = {
  question: 'Chairs are available in fabric and leather. Select one.',
  inputFields: [
    {
      text: 'Leather'
    },
    {
      text: 'Fabric'
    }
  ]
}

// budget options
export const budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$699-$1199',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1199-$2995',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$2995+',
      value: 'luxe'
    }
  ]
}

export const sofa_standard_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$1199-$2000',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$2000-$4700',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$4700+',
      value: 'luxe'
    }
  ]
}

export const sofa_chaise_sectional_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$1079-$2079',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$2079-$5100',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$5100+',
      value: 'luxe'
    }
  ]
}

export const sofa_modular_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$1119-$2599',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$2599-$4079',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$4079+',
      value: 'luxe'
    }
  ]
}

export const sofa_l_shaped_sectional_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$1599-$2800',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$2800-$5200',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$5200+',
      value: 'luxe'
    }
  ]
}

export const sofa_sleeper_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$1599-$2195',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$2195-$4200',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$4200+',
      value: 'luxe'
    }
  ]
}

export const chair_standard_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$399-$999',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$999-$1699',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1699+',
      value: 'luxe'
    }
  ]
}

export const chair_recliner_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$999-$1849',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1849-$2999',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$2999+',
      value: 'luxe'
    }
  ]
}

export const chair_swivel_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$699-$1099',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1099-$2199',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$2199+',
      value: 'luxe'
    }
  ]
}

export const dresser_horizontal_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$1199-$1699',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1699-$3299',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$3299+',
      value: 'luxe'
    }
  ]
}

export const dresser_vertical_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$519-$1039',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1039-$2995',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$2995+',
      value: 'luxe'
    }
  ]
}

export const sideboard_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$699-$1199',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1199-$2995',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$2995+',
      value: 'luxe'
    }
  ]
}

export const dining_table_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$399-$799',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$799-$1799',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1799+',
      value: 'luxe'
    }
  ]
}

export const dining_chairs_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$69-$299',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$299-$549',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$549+',
      value: 'luxe'
    }
  ]
}

export const stools_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$279-$495',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$495-$999',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$999+',
      value: 'luxe'
    }
  ]
}

export const nightstand_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$349-$445',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$445-$1099',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1099+',
      value: 'luxe'
    }
  ]
}

export const bed_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$799-$1099',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$1099-$2499',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$2499+',
      value: 'luxe'
    }
  ]
}

export const bench_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$299-$675',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$675-$1199',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1199+',
      value: 'luxe'
    }
  ]
}

export const end_table_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$149-$459',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$459-$1099',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1099+',
      value: 'luxe'
    }
  ]
}

export const rug_size_options = {
  question: 'What size rug do you need?',
  inputFields: [
    {
      info: "5' x 8'",
      value: '5x8'
    },
    {
      info: "6' x 9'",
      value: '6x9'
    },
    {
      info: "8' x 10'",
      value: '8x10'
    },
    {
      info: "9' x 12'",
      value: '9x12'
    },
    {
      info: "I'm not sure",
      value: 'notSure'
    }
  ]
}

export const rug_budget_5x8_options = {
  question: "Rug prices vary depending on size, materials, and manufacturer, but we can work within most budgets. What's your comfortable budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$275-$350',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$350-$575',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$575+',
      value: 'luxe'
    }
  ]
}

export const rug_budget_6x9_options = {
  question: "Rug prices vary depending on size, materials, and manufacturer, but we can work within most budgets. What's your comfortable budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$350-$600',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$600-$845',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$845+',
      value: 'luxe'
    }
  ]
}

export const rug_budget_8x10_options = {
  question: "Rug prices vary depending on size, materials, and manufacturer, but we can work within most budgets. What's your comfortable budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$545-$750',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$750-$1100',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1100+',
      value: 'luxe'
    }
  ]
}

export const rug_budget_9x12_options = {
  question: "Rug prices vary depending on size, materials, and manufacturer, but we can work within most budgets. What's your comfortable budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$750-$990',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$990-$1650',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1650+',
      value: 'luxe'
    }
  ]
}

export const coffee_budget_options = {
  question: "What's your preferred budget range?",
  inputFields: [
    {
      text: 'Budget',
      info: '$199-$599',
      value: 'budget'
    },
    {
      text: 'Premium',
      info: '$599-$1299',
      value: 'premium'
    },
    {
      text: 'Luxe',
      info: '$1299+',
      value: 'luxe'
    }
  ]
}

export const sideboard_type_options = [
  {
    value: 'sliding_doors',
    imagePublicID: 'ToTree/webapp/survey/living_room/sideboard/sliding_doors',
    label: 'Sliding doors'
  },
  {
    value: 'open_shelves',
    imagePublicID: 'ToTree/webapp/survey/living_room/sideboard/open_shelves',
    label: 'Open shelves'
  },
  {
    value: 'standard_doors',
    imagePublicID: 'ToTree/webapp/survey/living_room/sideboard/standard_doors',
    label: 'Standard doors'
  }
]

export const coffee_type_options = [
  {
    value: 'traditional',
    imagePublicID: 'ToTree/webapp/survey/living_room/coffee_table/traditional',
    label: 'Traditional'
  },
  {
    value: 'shelf',
    imagePublicID: 'ToTree/webapp/survey/living_room/coffee_table/shelf',
    label: 'Shelf'
  },
  {
    value: 'storage',
    imagePublicID: 'ToTree/webapp/survey/living_room/coffee_table/storage',
    label: 'Storage'
  }

]

export const coffee_surface_options = {
  question: 'What coffee table surface do you prefer?',
  inputFields: [
    {
      value: 'storage',
      text: 'Wood',
      imagePublicID: 'ToTree/webapp/survey/living_room/coffee_table/storage',
      questionsRefId: 'wood'
    },
    {
      value: 'glass',
      text: 'Glass',
      questionsRefId: 'glass',
      imagePublicID: 'ToTree/webapp/survey/living_room/coffee_table/glass'
    },
    {
      value: 'stone',
      text: 'Stone',
      questionsRefId: 'marble-stone',
      imagePublicID: 'ToTree/webapp/survey/living_room/coffee_table/stone'
    }
  ]
}

export const coffee_shape_options = {
  question: 'What coffee table shape do you prefer?',
  inputFields: [
    {
      value: 'round',
      text: 'Round',
      imagePublicID: 'ToTree/webapp/survey/shapes/round'
    },
    {
      value: 'rectangle',
      text: 'Rectangle',
      imagePublicID: 'ToTree/webapp/survey/shapes/rectangle'
    },
    {
      value: 'modern',
      text: 'Modern / funky',
      imagePublicID: 'ToTree/webapp/survey/shapes/modern'
    }
  ]
}

export const end_table_type_options = [
  {
    value: 'traditional',
    imagePublicID: 'ToTree/webapp/survey/living_room/end_table/traditional',
    label: 'Traditional'
  },
  {
    value: 'storage',
    imagePublicID: 'ToTree/webapp/survey/living_room/end_table/storage',
    label: 'Storage'
  }
]

export const end_table_surface_options = {
  question: 'What end table surface do you prefer?',
  inputFields: [
    {
      value: 'wood',
      text: 'Wood',
      imagePublicID: 'ToTree/webapp/survey/living_room/end_table/wood',
      questionsRefId: 'wood'
    },
    {
      value: 'glass',
      text: 'Glass',
      questionsRefId: 'glass',
      imagePublicID: 'ToTree/webapp/survey/living_room/end_table/glass'
    },
    {
      value: 'stone',
      text: 'Stone',
      questionsRefId: 'marble-stone',
      imagePublicID: 'ToTree/webapp/survey/living_room/end_table/stone'
    }
  ]
}

export const end_table_shape_options = {
  question: 'What end table shape do you prefer?',
  inputFields: [
    {
      value: 'round',
      text: 'Round',
      imagePublicID: 'ToTree/webapp/survey/shapes/round'
    },
    {
      value: 'rectangle',
      text: 'Rectangle',
      imagePublicID: 'ToTree/webapp/survey/shapes/rectangle'
    },
    {
      value: 'square',
      text: 'Square',
      imagePublicID: 'ToTree/webapp/survey/shapes/square'
    }
  ]
}

export const rug_style_options = {
  question: 'Rugs are a great way to add character to a space. What style of rug(s) do you prefer?',
  inputFields: [
    {
      value: 'style_1',
      imagePublicID: 'ToTree/webapp/survey/shared/rug/style_1'
    },
    {
      value: 'style_2',
      imagePublicID: 'ToTree/webapp/survey/shared/rug/style_2'
    },
    {
      value: 'style_3',
      imagePublicID: 'ToTree/webapp/survey/shared/rug/style_3'
    },
    {
      value: 'style_4',
      imagePublicID: 'ToTree/webapp/survey/shared/rug/style_4'
    },
    {
      value: 'style_5',
      imagePublicID: 'ToTree/webapp/survey/shared/rug/style_5'
    }
  ]
}

export const living_room_colors_options = [
  {
    value: 'neutral',
    imagePublicID: 'ToTree/webapp/survey/living_room/color/neutral',
    label: 'All Neutral'
  },
  {
    value: 'accent',
    imagePublicID: 'ToTree/webapp/survey/living_room/color/accent',
    label: 'An Accent'
  },
  {
    value: 'lots_of_color',
    imagePublicID: 'ToTree/webapp/survey/living_room/color/lots_of_color',
    label: 'Lots of color'
  }

]

export const color_options = {
  question: 'Which colors would you like?',
  colors: true,
  inputFields: [
    {
      value: 'green',
      text: 'Greens',
      color: 'green'
    },
    {
      value: 'blue',
      text: 'Blues',
      color: 'blue'
    },
    {
      value: 'red',
      text: 'Reds',
      color: 'red'
    }
  ]
}

// templates
export const living_room_template = [
  {
    sectionTitle: 'The Sofa',
    sectionId: 'sofa',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of sofa are you looking for?',
        vmodel: 'type',
        options: 'sofa_type_options',
        questionsRefId: 'sofa-type-q'
      },
      {
        type: 'StyleRadio',
        header: 'What style of sofa do you prefer?',
        vmodel: 'style',
        options: 'sofa_style_options',
        questionsRefId: 'sofa-style-q'
      },
      {
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'sofa_fabric_options',
        questionsRefId: 'sofa-fabric-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'budget_options',
        questionsRefId: 'sofa-budget-q',
        type_dependent: true
      }
    ]
  },
  {
    sectionTitle: 'The Chair',
    sectionId: 'chair',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of chair are you looking for?',
        vmodel: 'type',
        options: 'chair_type_options',
        questionsRefId: 'chair-type-q'
      },
      {
        conditional: { name: 'type', option: 'standard' },
        type: 'StyleRadio',
        header: 'What style of chair are you looking for?',
        vmodel: 'style',
        options: 'chair_style_options',
        questionsRefId: 'chair-style-q'
      },
      {
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'chair_fabric_options',
        questionsRefId: 'chair-fabric-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'budget_options',
        questionsRefId: 'chair-budget-q',
        type_dependent: true
      }
    ]
  },
  {
    sectionTitle: 'The Sideboard/Media Console',
    sectionId: 'sideboard',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of sideboard/media console are you looking for?',
        vmodel: 'type',
        options: 'sideboard_type_options',
        questionsRefId: 'sideboard-type-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'sideboard_budget_options',
        questionsRefId: 'sideboard-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Coffee Table',
    sectionId: 'coffee_table',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of coffee table are you looking for?',
        vmodel: 'type',
        options: 'coffee_type_options',
        questionsRefId: 'coffee-type-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'surface',
        question: 'coffee_surface_options',
        questionsRefId: 'coffee-surface-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'shape',
        question: 'coffee_shape_options',
        questionsRefId: 'coffee-shape-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'coffee_budget_options',
        questionsRefId: 'coffee-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The End Table',
    sectionId: 'end_table',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of end table(s) are you looking for?',
        vmodel: 'type',
        options: 'end_table_type_options',
        questionsRefId: 'end-table-type-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'surface',
        question: 'end_table_surface_options',
        questionsRefId: 'end-table-surface-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'shape',
        question: 'end_table_shape_options',
        questionsRefId: 'end-table-shape-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'end_table_budget_options',
        questionsRefId: 'end-table-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Rug',
    sectionId: 'rug',
    subsections: [
      {
        type: 'StyleCheckbox',
        vmodel: 'style',
        question: 'rug_style_options',
        questionsRefId: 'rug-style-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'size',
        question: 'rug_size_options',
        questionsRefId: 'rug-size-q'
      },
      {
        conditional: { name: 'size', option: '5x8' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_5x8_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '6x9' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_6x9_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '8x10' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_8x10_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '9x12' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_9x12_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: 'notSure' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_8x10_options',
        questionsRefId: 'rug-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Color',
    description: 'We believe foundational furniture should be versatile, so we select mostly neutral pieces with bits of color here and there.',
    sectionId: 'color',
    subsections: [
      {
        type: 'StyleRadio',
        header: "That said, if you're looking for some color, we're happy to help. How much color would you like?",
        vmodel: 'colorIntensity',
        options: 'living_room_colors_options',
        questionsRefId: 'living-room-color-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'color',
        question: 'color_options',
        questionsRefId: 'color-options-q'
      }
    ]
  }
]

// bedroom template
export const bedroom_template = [
  {
    sectionTitle: 'The Bed',
    sectionId: 'bed',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of bed are you looking for?',
        vmodel: 'type',
        options: 'bed_type_options',
        questionsRefId: 'bed-type-q'
      },
      {
        conditional: { name: 'type', option: 'headboard' },
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'bed_fabric_options',
        questionsRefId: 'bed-fabric-q'
      },
      {
        type: 'RadioTt',
        vmodel: 'size',
        question: 'bed_size_options',
        questionsRefId: 'bed-size-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'bed_budget_options',
        questionsRefId: 'bed-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Dresser',
    sectionId: 'dresser',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of dresser are you looking for?',
        vmodel: 'type',
        options: 'dresser_type_options',
        questionsRefId: 'dresser-type-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'budget_options',
        questionsRefId: 'dresser-budget-q',
        type_dependent: true
      }
    ]
  },
  {
    sectionTitle: 'The Nightstand',
    sectionId: 'nightstand',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of nightstand(s) are you looking for?',
        vmodel: 'type',
        options: 'nightstand_type_options',
        questionsRefId: 'nightstand-type-q'
      },
      {
        type: 'StyleRadio',
        header: 'Select the nightstand surface you prefer.',
        vmodel: 'surface',
        options: 'nightstand_surface_options',
        questionsRefId: 'nightstand-surface-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'nightstand_budget_options',
        questionsRefId: 'nightstand-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Chair',
    sectionId: 'chair',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of chair are you looking for?',
        vmodel: 'type',
        options: 'chair_type_options',
        questionsRefId: 'chair-type-q'
      },
      {
        conditional: { name: 'type', option: 'standard' },
        type: 'StyleRadio',
        header: 'What style of chair are you looking for?',
        vmodel: 'style',
        options: 'chair_style_options',
        questionsRefId: 'chair-style-q'
      },
      {
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'chair_fabric_options',
        questionsRefId: 'chair-fabric-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'budget_options',
        questionsRefId: 'chair-budget-q',
        type_dependent: true
      }
    ]
  },
  {
    sectionTitle: 'The Bench',
    sectionId: 'bench',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'What type of bench are you looking for?',
        vmodel: 'type',
        options: 'bench_type_options',
        questionsRefId: 'bench-type-q'
      },
      {
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'bench_fabric_options',
        questionsRefId: 'bench-fabric-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'bench_budget_options',
        questionsRefId: 'bench-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Rug',
    sectionId: 'rug',
    subsections: [
      {
        type: 'StyleCheckbox',
        vmodel: 'style',
        question: 'rug_style_options',
        questionsRefId: 'rug-style-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'size',
        question: 'rug_size_options',
        questionsRefId: 'rug-size-q'
      },
      {
        conditional: { name: 'size', option: '5x8' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_5x8_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '6x9' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_6x9_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '8x10' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_8x10_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '9x12' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_9x12_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: 'notSure' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_8x10_options',
        questionsRefId: 'rug-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Color',
    description: 'We believe foundational furniture should be versatile, so we select mostly neutral pieces with bits of color here and there.',
    sectionId: 'color',
    subsections: [
      {
        type: 'StyleRadio',
        header: "That said, if you're looking for some color, we're happy to help. How much color would you like?",
        vmodel: 'colorIntensity',
        options: 'bedroom_colors_options',
        questionsRefId: 'bedroom-color-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'color',
        question: 'color_options',
        questionsRefId: 'color-options-q'
      }
    ]
  }
]

// dining_room template
export const dining_room_template = [
  {
    sectionTitle: 'The Table',
    sectionId: 'dining_table',
    subsections: [
      {
        type: 'StyleRadio',
        header: 'Dining tables are available in different materials. Select which materials you like.',
        vmodel: 'type',
        options: 'table_type_options',
        questionsRefId: 'table-type-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'shape_type',
        question: 'table_shape_options',
        questionsRefId: 'table-shape-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'dining_table_budget_options',
        questionsRefId: 'table-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Chairs',
    sectionId: 'dining_chairs',
    subsections: [
      {
        type: 'RadioTt',
        vmodel: 'quantity',
        question: 'chairs_quantity_options',
        questionsRefId: 'chairs-quantity-q'
      },
      {
        conditional: { name: 'quantity', option: 'Other' },
        type: 'TextAreaTt',
        question: 'Please enter the number below.',
        vmodel: 'quantity_other',
        questionsRefId: 'chairs-quantity-other-q'
      },
      {
        type: 'StyleRadio',
        header: 'Dining chairs are available as wood or upholstered. Which do you prefer?',
        vmodel: 'type',
        options: 'chairs_type_options',
        questionsRefId: 'chairs-type-q'
      },
      {
        conditional: { name: 'type', option: 'upholstered' },
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'chairs_fabric_options',
        questionsRefId: 'chairs-fabric-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'dining_chairs_budget_options',
        questionsRefId: 'chairs-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Stools',
    sectionId: 'stools',
    subsections: [
      {
        type: 'RadioTt',
        vmodel: 'size',
        question: 'stools_size_options',
        questionsRefId: 'stools-size-q'
      },
      {
        type: 'TextAreaTt',
        question: 'Enter number of stools needed.',
        vmodel: 'quantity_other',
        questionsRefId: 'chairs-quantity-other-q'
      },
      {
        type: 'StyleRadio',
        header: 'Stools are available as wood or upholstered. Which do you prefer?',
        vmodel: 'type',
        options: 'stools_type_options',
        questionsRefId: 'stools-type-q'
      },
      {
        conditional: { name: 'type', option: 'upholstered' },
        type: 'RadioTt',
        vmodel: 'materials',
        question: 'stools_fabric_options',
        questionsRefId: 'stools-fabric-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'stools_budget_options',
        questionsRefId: 'stools-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Rug',
    sectionId: 'rug',
    subsections: [
      {
        type: 'StyleCheckbox',
        vmodel: 'style',
        question: 'rug_style_options',
        questionsRefId: 'rug-style-q'
      },
      {
        type: 'RadioBudget',
        vmodel: 'size',
        question: 'rug_size_options',
        questionsRefId: 'rug-size-q'
      },
      {
        conditional: { name: 'size', option: '5x8' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_5x8_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '6x9' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_6x9_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '8x10' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_8x10_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: '9x12' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_9x12_options',
        questionsRefId: 'rug-budget-q'
      },
      {
        conditional: { name: 'size', option: 'notSure' },
        type: 'RadioBudget',
        vmodel: 'budget',
        question: 'rug_budget_8x10_options',
        questionsRefId: 'rug-budget-q'
      }
    ]
  },
  {
    sectionTitle: 'The Color',
    description: 'We believe foundational furniture should be versatile, so we select mostly neutral pieces with bits of color here and there.',
    sectionId: 'color',
    subsections: [
      {
        type: 'StyleRadio',
        header: "That said, if you're looking for some color, we're happy to help. How much color would you like?",
        vmodel: 'colorIntensity',
        options: 'dining_room_colors_options',
        questionsRefId: 'dining-room-color-q'
      },
      {
        type: 'StyleCheckbox',
        vmodel: 'color',
        question: 'color_options',
        questionsRefId: 'color-options-q'
      }
    ]
  }
]

export const bed_type_options = [
  {
    value: 'no_headboard',
    imagePublicID: 'ToTree/webapp/survey/bedroom/bed/no_headboard',
    label: 'No headboard'
  },
  {
    value: 'headboard',
    imagePublicID: 'ToTree/webapp/survey/bedroom/bed/headboard',
    label: 'Headboard'
  }
]

export const bed_fabric_options = {
  question: 'Headboards are available in different materials. Select one.',
  inputFields: [
    {
      text: 'Wood'
    },
    {
      text: 'Fabric'
    }
  ]
}

export const bed_size_options = {
  question: 'What size bed are you looking for?',
  inputFields: [
    {
      text: 'King'
    },
    {
      text: 'Queen'
    },
    {
      text: 'Full'
    }
  ]
}

export const dresser_type_options = [
  {
    value: 'horizontal',
    imagePublicID: 'ToTree/webapp/survey/bedroom/dresser/horizontal',
    label: 'Horizontal'
  },
  {
    value: 'vertical',
    imagePublicID: 'ToTree/webapp/survey/bedroom/dresser/vertical',
    label: 'Vertical'
  }
]

export const nightstand_type_options = [
  {
    value: 'drawer_only',
    imagePublicID: 'ToTree/webapp/survey/bedroom/nightstand/drawer_only',
    label: 'Drawer only'
  },
  {
    value: 'shelf',
    imagePublicID: 'ToTree/webapp/survey/bedroom/nightstand/shelf',
    label: 'Shelf'
  }
]

export const nightstand_surface_options = [
  {
    value: 'wood',
    imagePublicID: 'ToTree/webapp/survey/bedroom/nightstand/wood',
    label: 'Wood'
  },
  {
    value: 'stone',
    imagePublicID: 'ToTree/webapp/survey/bedroom/nightstand/stone',
    label: 'Stone'
  }
]

export const bench_type_options = [
  {
    value: 'standard',
    imagePublicID: 'ToTree/webapp/survey/shared/bench/standard',
    label: 'Standard'
  },
  {
    value: 'storage',
    imagePublicID: 'ToTree/webapp/survey/shared/bench/storage',
    label: 'Storage'
  }
]

export const bench_fabric_options = {
  question: 'Benches are available in both fabric and leather. Select your preference.',
  inputFields: [
    {
      text: 'Leather'
    },
    {
      text: 'Fabric'
    }
  ]
}

export const bedroom_colors_options = [
  {
    value: 'neutral',
    imagePublicID: 'ToTree/webapp/survey/bedroom/color/neutral',
    label: 'All Neutral'
  },
  {
    value: 'accent',
    imagePublicID: 'ToTree/webapp/survey/bedroom/color/accent',
    label: 'An Accent'
  },
  {
    value: 'lots_of_color',
    imagePublicID: 'ToTree/webapp/survey/bedroom/color/lots_of_colors',
    label: 'Lots of color'
  }
]

export const table_type_options = [
  {
    value: 'wood',
    imagePublicID: 'ToTree/webapp/survey/dining_room/table/wood',
    label: 'Wood'
  },
  {
    value: 'glass',
    imagePublicID: 'ToTree/webapp/survey/dining_room/table/glass',
    label: 'Glass top'
  },
  {
    value: 'stone',
    imagePublicID: 'ToTree/webapp/survey/dining_room/table/stone',
    label: 'Stone top'
  }
]

export const table_shape_options = {
  question: 'What dining table shape do you prefer?',
  inputFields: [
    {
      value: 'round',
      text: 'Round',
      imagePublicID: 'ToTree/webapp/survey/shapes/round'
    },
    {
      value: 'rectangle',
      text: 'Rectangle',
      imagePublicID: 'ToTree/webapp/survey/shapes/rectangle'
    },
    {
      value: 'square',
      text: 'Square',
      imagePublicID: 'ToTree/webapp/survey/shapes/square'
    }
  ]
}

export const chairs_type_options = [
  {
    value: 'wood',
    imagePublicID: 'ToTree/webapp/survey/dining_room/chair/wood',
    label: 'Wood'
  },
  {
    value: 'upholstered',
    imagePublicID: 'ToTree/webapp/survey/dining_room/chair/upholstered',
    label: 'Upholstered'
  }
]

export const chairs_fabric_options = {
  question: 'Upholstered dining chairs are available in fabric and leather. Select one.',
  inputFields: [
    {
      text: 'Fabric'
    },
    {
      text: 'Leather'
    }
  ]
}

export const chairs_quantity_options = {
  question: 'How many people do you want to accommodate in your dining space?',
  inputFields: [
    {
      text: '2-3'
    },
    {
      text: '4'
    },
    {
      text: '6+'
    },
    {
      text: 'Other'
    }
  ]
}

export const stools_size_options = {
  question: 'Stools come in two standard sizes (simply measure from the countertop to the floor!)',
  inputFields: [
    {
      text: 'Kitchen counter stools: (for counter tops 36" high)'
    },
    {
      text: 'Bar counter stools: (for counter tops 42" high)'
    },
    {
      text: 'My counter does not fall within either option'
    }
  ]
}

export const stools_type_options = [
  {
    value: 'wood',
    imagePublicID: 'ToTree/webapp/survey/dining_room/stool/wood',
    label: 'Wood'
  },
  {
    value: 'upholstered',
    imagePublicID: 'ToTree/webapp/survey/dining_room/stool/upholstered',
    label: 'Upholstered'
  }
]

export const stools_fabric_options = {
  question: 'Upholstered stools are available in fabric and leather. Select one.',
  inputFields: [
    {
      text: 'Fabric'
    },
    {
      text: 'Leather'
    }
  ]
}

export const dining_room_colors_options = [
  {
    value: 'neutral',
    imagePublicID: 'ToTree/webapp/survey/dining_room/color/neutral',
    label: 'All Neutral'
  },
  {
    value: 'lots_of_color',
    imagePublicID: 'ToTree/webapp/survey/dining_room/color/lots_of_colors',
    label: 'Lots of color'
  }
]

// Radio
export const type_of_sofa = {
  question: 'Is there a particular style of sofa you’d like?',
  inputFields: [
    {
      value: 'standard',
      text: 'Standard',
      green_img: 'n-sofa-0.svg',
      image: 'n-sofa-0.svg'
    },
    {
      value: 'l-shaped-sectional',
      text: 'L-shaped sectional sofa',
      green_img: 'n-sofa-1.svg',
      image: 'n-sofa-1.svg'
    },

    {
      value: 'sleeper-sofa',
      text: 'Sleeper Sofa',
      green_img: 'n-sofa-2.svg',
      image: 'n-sofa-2.svg'
    },
    {
      value: 'chaise-sectional',
      text: 'Chaise Sectional Sofa',
      green_img: 'n-sofa-3.svg',
      image: 'n-sofa-3.svg'
    },
    {
      value: 'modular',
      text: 'Modular',
      green_img: 'n-sofa-4.svg',
      image: 'n-sofa-4.svg'
    },
    {
      value: 'no-whatever',
      text: 'No, whatever you think will work best',
      green_img: 'no-sofa.svg',
      image: 'no-sofa.svg'
    }
  ]
}

// Radio with Imagery
export const chair_options = [
  {
    value: 'chair-1',
    green_img: 'chair1.svg',
    image: 'chair1.svg'
  },
  {
    value: 'chair-2',
    green_img: 'chair2.svg',
    image: 'chair2.svg'
  }
]

// Radio
export const chair_fabric = {
  question: 'Chairs are available in both fabric and leather. Any preference?',
  inputFields: [
    { text: 'Leather' },
    { text: 'Fabric' },
    { text: 'No preference' }
  ]
}

// Textarea
export const sofa_notes = {
  question: 'Any other specifics you like in a sofa? (optional)'
}

/*
  ______          _
  | ___ \        | |
  | |_/ / ___  __| |_ __ ___   ___  _ __ ___
  | ___ \/ _ \/ _` | '__/ _ \ / _ \| '_ ` _ \
  | |_/ /  __/ (_| | | | (_) | (_) | | | | | |
  \____/ \___|\__,_|_|  \___/ \___/|_| |_| |_|
*/
export const bed_options = [
  {
    value: 'bed-1',
    green_img: 'bed1.svg',
    image: 'bed1.svg'
  },
  {
    value: 'bed-2',
    green_img: 'bed2.svg',
    image: 'bed2.svg'
  },
  {
    value: 'bed-3',
    green_img: 'bed3.svg',
    image: 'bed3.svg'
  }
]

// Radio
export const bed_size = {
  question: 'What size bed are you looking for?',
  inputFields: [
    { text: 'King' },
    { text: 'Queen' },
    { text: 'Full' }
  ]
}

// Checkbox
export const other_pieces_bedroom = {
  question: 'Other than the basics, would you be interested in anything else for your bedroom?',
  inputFields: [
    { text: 'Chair' },
    { text: 'Chair with ottoman' },
    { text: 'Bench' },
    { text: 'Extra dresser' },
    // { text: 'Office furniture' }, // KEEP: OFFICE
    { text: 'No thanks' }
  ]
}

// Textarea
export const budget_notes = {
  question: 'Like you, we care about costs.',
  text: '<p>Working within a customer’s budget is a priority at toTree, so if you have specific numbers or a range in mind, please jot them down here:</p>'
}

// Text only
export const budget_note = {
  type: 'note',
  title: 'No clue about the costs?',
  text: '<p>Check out <a href="/about/">this quick story</a> from toTree’s founder who was in precisely the same boat when he went to buy quality furniture for the first time.</p><p>If neither of those options sounds appealing and you still have some questions/concerns/thoughts, reach out to lyndsey@totree.co/919-914-9888 and we’ll be happy to help!</p>'
}

/*
  ______ _       _              ______
  |  _  (_)     (_)             | ___ \
  | | | |_ _ __  _ _ __   __ _  | |_/ /___   ___  _ __ ___
  | | | | | '_ \| | '_ \ / _` | |    // _ \ / _ \| '_ ` _ \
  | |/ /| | | | | | | | | (_| | | |\ \ (_) | (_) | | | | | |
  |___/ |_|_| |_|_|_| |_|\__, | \_| \_\___/ \___/|_| |_| |_|
                          __/ |
                          |___/
*/
// Radio
export const dining_room_number_of_people = {
  question: 'How many people do you want to accommodate in your dining space?',
  inputFields: [
    { text: '2-3' },
    { text: '4' },
    { text: '6+' },
    { text: 'Other' }
  ]
}

// Radio
export const table_height = [
  {
    value: 'Bistro',
    label: 'Bistro',
    green_img: 'table-bistro.jpg',
    image: 'table-bistro.jpg'
  },
  {
    value: 'Standard height',
    label: 'Standard height',
    green_img: 'table-standard.png',
    image: 'table-standard.png'
  }
]

// Radio
export const existing_bar_height = {
  question: 'Stools come in two standard sizes (simply measure from the countertop to the floor!)',
  inputFields: [
    { text: 'Kitchen counter stools: (for counter tops 36” high)' },
    { text: 'Bar counter stools: (for counter tops 42” high)' },
    { text: 'My counter does not fall within either option' }
  ]
}
// Text Input
export const number_of_stools_needed = {
  question: 'Enter number of stools needed.'
}

/*
   _____  __  __ _
  |  _  |/ _|/ _(_)
  | | | | |_| |_ _  ___ ___
  | | | |  _|  _| |/ __/ _ \
  \ \_/ / | | | | | (_|  __/
   \___/|_| |_| |_|\___\___|
*/
// Textarea
export const how_tall = {
  question: 'How tall are you?'
}

// Radio
export const chair_type = {
  question: 'Would you like an office desk chair, or a standard chair?',
  inputFields: [
    { text: 'Office chair' },
    { text: 'Standard chair' },
    { text: 'I\'d like to see one of each' }
  ]
}

// Checkbox
export const other_pieces_office = {
  question: 'Do you want any additional pieces in your office?',
  inputFields: [
    { text: 'Chair' },
    { text: 'Ottoman' },
    { text: 'Side table' },
    { text: 'Loveseat or sofa' },
    { text: 'Additional storage' },
    { text: 'No thanks' }
  ]
}

/*
   _______ _________          _        _______
  (  ____ \\__   __/|\     /|( \      (  ____ \
  | (    \/   ) (   ( \   / )| (      | (    \/
  | (_____    | |    \ (_) / | |      | (__
  (_____  )   | |     \   /  | |      |  __)
        ) |   | |      ) (   | |      | (
  /\____) |   | |      | |   | (____/\| (____/\
  \_______)   )_(      \_/   (_______/(_______/
*/

export const color_importance = {
  question: 'On a scale of 1-10, how important is seeing furniture options with a pop of color?'
}

// Checkbox
export const favorite_color = {
  question: 'Pick a color, any color!',
  inputFields: [
    {
      text: 'Reds'
    },
    {
      text: 'Blues'
    },
    {
      text: 'Greens'
    }
  ]
}

// Textarea
export const style_notes = {
  question: 'Something we didn’t cover?'
}

export const inspiration = {
  question: 'Inspiration? Images? Upload pictures, links or Pinterest boards here:'
}

// Radio
export const sofa_placement = {
  question: 'Where are you going to place your sofa?',
  inputFields: [
    { text: 'Wall A' },
    { text: 'Wall B' },
    { text: 'I don\'t know, can you help me?' }
  ]
}

// Textarea
export const room_notes = {
  question: 'Anything additional to note for this room?'
}
